import * as React from 'react';

import { LegendBox } from '@independent-software/mapbox-ext/controls/Legend/LegendBox';

import { Number } from '../formatters/Number';
import { Config } from '../config/Config';
import { COLOR_HIGHLY_SUITABLE, COLOR_MODERATELY_SUITABLE, COLOR_NOT_SUITABLE, COLOR_SUITABLE } from '../config/Colors';

interface IProps {
  name: string;
  value: any;
}

const TranslatedValue = (props: IProps) => {

  const getSuitability = (value: number): string => {
    switch(value) {
      case 2: return "Moderately suitable";
      case 3: return "Suitable";
      case 4: return "Highly suitable";
      default: return "Not suitable";
    }
  }

  const getSuitabilityColor = (value: number): string => {
    switch(value) {
      case 2: return COLOR_MODERATELY_SUITABLE;
      case 3: return COLOR_SUITABLE;
      case 4: return COLOR_HIGHLY_SUITABLE;
      default: return COLOR_NOT_SUITABLE;
    }    
  }

  const attribute = Config.dictionary.find(x => x.key == props.name);

  if(!attribute) return props.value;

  switch(attribute.type) {
    case 'suitability':
      return <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '4px'}}><LegendBox color={getSuitabilityColor(props.value)}/> {getSuitability(props.value)}</div>;
    case 'number':
      return <><Number value={props.value * (attribute.factor ? attribute.factor : 1)} decimals={2}/> {attribute.unit}</>
    default:
      return props.value;
  }    
}

export { TranslatedValue }
