import * as React from 'react';
import styled, {css} from 'styled-components';

interface IProps {
  className?: string;
  children?: React.ReactNode;
  horizontal: 'left' | 'right';
  vertical: 'top' | 'bottom';
}

class PopupBalloonBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    );
  }
}

const PopupBalloon = styled(PopupBalloonBase)`
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;
  pointer-events: none;
  ${p => (p.vertical == "bottom" ) && css`
    &:before {
      content: '';
      position: absolute;
      ${p.horizontal == "right" && css`right:16px;`}
      ${p.horizontal == "left" && css`left:16px;`}
      bottom: -4px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-top: 20px solid #fff;
    }
    &:after {
      content: '';
      position: absolute;
      ${p.horizontal == "right" && css`right:18px;`}
      ${p.horizontal == "left" && css`left:18px;`}
      bottom: -2px;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-top: 18px solid #333;
    }
  `}
  ${p => (p.vertical == "top") && css`
    &:before {
      content: '';
      position: absolute;
      ${p.horizontal == "right" && css`right:16px;`}
      ${p.horizontal == "left" && css`left:16px;`}
      top: -9px;
      width: 0;
      height: 0;
      border-left: 20px solid transparent;
      border-right: 20px solid transparent;
      border-bottom: 20px solid #fff;
    }
    &:after {
      content: '';
      position: absolute;
      ${p.horizontal == "right" && css`right:18px;`}
      ${p.horizontal == "left" && css`left:18px;`}
      top: -7px;
      width: 0;
      height: 0;
      border-left: 18px solid transparent;
      border-right: 18px solid transparent;
      border-bottom: 18px solid #333;
    }
  `}  
`

export { PopupBalloon }
