import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideButton } from '../elements/GuideButton';
import { GuideIcon } from '../elements/GuideIcon';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const LayersGuide = (props: IProps) => {
  return (
    <GuideBox top="32px" right="92px" onCancel={props.onCancel}>
      <h1>Map layers</h1>
      <p><em>The map can display different layers of aquaculture suitability.</em></p>
      <p>Use the tabs <GuideIcon icon="icon-offshore-aquaculture"/> on the right to toggle layers.</p>
      <GuideButton onClick={() => props.onStage(null)}>Finish</GuideButton>
    </GuideBox>
  );
}

export { LayersGuide }
