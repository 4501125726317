import * as React from 'react';
import styled from 'styled-components';

const WIDTH = 235;

interface IProps { 
  className?: string;
  children?: React.ReactNode;
  /** SVG icon name. */
  icon: string;
  /** Text to display on piano key. */
  title: string;
  /** Is piano key currently active? */
  active?: boolean;
  /** Fired when piano key is clicked. */
  onClick?: () => void;
}

class PianoKeyBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <PianoKeyHolder>
        <div className={p.className} >
          <div className="key" onClick={p.onClick}>
            <svg><use xlinkHref={`sprites.svg#${p.icon}`}></use></svg>
            {p.title}
          </div>
          {React.Children.count(p.children) > 0 && 
              <Panel>
                <PanelContent>
                  {p.children}
                </PanelContent>
              </Panel>}        
        </div>
      </PianoKeyHolder>
    );
  }
}

const PianoKeyHolder = styled('div')`
  position: relative;
  height: 47px;
  width: 0;
`

const PianoKey = styled(PianoKeyBase)`
  position: absolute;
  right: ${p => p.active ? -(WIDTH - 29 - 30) : -(WIDTH - 25 - 30)}px;
  width: ${WIDTH}px;
  user-select: none;

  div.key {
    box-sizing: border-box;
    height: 36px;
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;
    border: solid 2px ${p => p.active ? "#d3d3d3" : "#90ADBE"};
    background-color: ${p => p.active ? "#ffffff" : "#58A9DD"};
    border-right: none;
    border-top-left-radius: 12px;
    border-bottom-left-radius: 12px;
    padding-left: 8px;
    color: ${p => p.active ? "#505050" : "#ffffff"};    
    svg {
      width: 20px;
      height: 20px;
      fill: ${p => p.active ? "#000000" : "#ffffff"};
      margin-right: 28px;
    }    
  }
  
  transition: right ease-in-out 250ms, background-color ease-in-out 150ms;
  &:hover {
    right: 0;
  }
`

const Panel = styled('div')`
  position: absolute;
  left: 40px; 
  top: 38px;
  width: 100%;
  color: #505050;
`

const PanelContent = styled('div')`
  width: 100%;
  margin-top: 5px;
  background: white;
  border-radius: 8px;
  padding: 8px 12px 8px 12px;
`

export { PianoKey }
