import * as React from 'react';
import styled, { css } from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;  
  
  top?: string;
  left?: string;
  bottom?: string;
  right?: string;

  // Fired when cancel button is clicked.
  onCancel: () => void;
}

const GuideBoxBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <Cross onClick={props.onCancel} title="Cancel tutorial">
        <svg>
          <use xlinkHref={"sprites.svg#times"}></use>
        </svg>            
      </Cross>
      {props.children}
    </div>
  );
}

const GuideBox = styled(GuideBoxBase)`
  position: absolute;
  z-index: 1;
  ${p => p.left && css`left: ${p.left};`}
  ${p => p.right && css`right: ${p.right};`}
  ${p => p.top && css`top: ${p.top};`}
  ${p => p.bottom && css`bottom: ${p.bottom};`}

  width: 250px;

  user-select: none;
  font-size: 12px;

  background: #111;
  color: #fff;
  line-height: 18px;
  border-radius: 4px;
  padding: 16px 32px 16px 24px;

  h1 {
    font-size: 18px;
    margin-bottom: 6px;
    white-space: nowrap;
    line-height: 20px;
    color: #fff;
    font-weight: normal;
  }
  strong {
    font-weight: 500;
  }
  em {
    font-style: normal;
    color: gold;
  }
  p {
    margin-bottom: 6px;
  }
`

const Cross = styled.div`
  position: absolute;
  right: 10px;
  top: 10px;
  width: 16px;
  height: 16px;
  cursor: pointer;
  svg {
    fill: #eee;
    width: 16px;
    height: 16px;
  }
  &:hover {
    svg { 
      fill: #fff;
    }
  }
`

export { GuideBox }
