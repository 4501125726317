import * as React from 'react';
import { IConfig } from './Config.Type';
import { PrebuiltExpressions } from '../layer/PrebuiltExpressions';

const Config: IConfig = {
  mapKey: "pk.eyJ1IjoibG9uZ2xpbmVlbnZpcm9ubWVudCIsImEiOiJjbGF0cHF1ZWUwM2l0M3FwcDcyN3B1YXpmIn0.snFi9yTPEZ5lfQxE3h3Epg",
  greyStyle: "mapbox://styles/longlineenvironment/clatpsjsl003r15okdwsdclmi",
  satelliteStyle: "mapbox://styles/longlineenvironment/clb14ar2y00hw14oh9ii2zp05",
  longitude: -24.05, 
  latitude: 16.15,   
  minZoom: 7,
  maxZoom: 14,
  max_bounds: [
    [-28.49, 12.97],    // Southwest (lng/lat)
    [-17.31, 20.02]     // Northeast (lng/lat)
  ],
  height: 75,
  layers: {
    'offshore_aquaculture': {
      src: 'offshore_aquaculture.geojson',
      name: 'Offshore aquaculture',
      active: true,
      icon: 'icon-offshore-aquaculture',
      fillPaint: PrebuiltExpressions.FillSuitability,
      linePaint: PrebuiltExpressions.LineBlackGoldResponsive
    }, 
    'onshore_aquaculture': {
      src: 'onshore_aquaculture.geojson',
      name: 'Onshore aquaculture',
      active: false,
      icon: 'icon-inshore-aquaculture',
      fillPaint: PrebuiltExpressions.FillSuitability,
      linePaint: PrebuiltExpressions.LineBlackGoldResponsive
    },
    /* 'offshore_amberjack': {
      src: 'offshore_amberjack_vector.geojson',
      name: 'Amberjack',
      active: false,
      icon: 'icon-tilapia-suitability',
      fillPaint: PrebuiltExpressions.FillSuitability,
      linePaint: PrebuiltExpressions.LineBlackGoldResponsive
    } */
  },
  dictionary: [
    {
      key: 'suitability',
      text: 'Suitability',
      type: 'suitability'
    }, {
      key: 'island',
      text: 'Island',
      type: 'string'
    }, {
      key: 'area_km2',
      text: 'Area',
      unit: <>km&sup2;</>,
      type: 'number',
      factor: 1
    }, {
      key: 'area_ha', // Original is hectares, we convert to km2 using a factor.
      text: 'Area',
      unit: <>km&sup2;</>,
      type: 'number',
      factor: 0.01
    },
  ],
  masonry: [
    { title: "Boa Vista", image: "boavista.jpg",      onshore: { none: 58394.16, low: 2757.32, medium: 1159.02, high: 83.64 }, offshore: { low: 0,      medium: 1143.61, high: 4.08 }},
    { title: "Brava", image: "brava.jpg",             onshore: { none: 6454.15,  low: 64,      medium: 0,       high: 0 },     offshore: { low: 3.43,   medium: 0,       high: 0 }},
    { title: "Fogo", image: "fogo.jpg",               onshore: { none: 140488.8, low: 41.65,   medium: 0.99,    high: 0 },     offshore: { low: 0,      medium: 0,       high: 0 }},
    { title: "Maio", image: "maio.jpg",               onshore: { none: 21342.25, low: 4635.82, medium: 673.3,   high: 18.04 }, offshore: { low: 210.33, medium: 0,       high: 19.59 }},
    { title: "Sal", image: "ilha-do-sal.jpg",         onshore: { none: 18913/14, low: 2183.98, medium: 564.85,  high: 4.08 },  offshore: { low: 0,      medium: 69.07,   high: 2.08 }},
    { title: "Santiago", image: "santiago.jpg",       onshore: { none: 889841.8, low: 908.08,  medium: 147.68,  high: 0.99 },  offshore: { low: 0,      medium: 21.35,   high: 1.73 }},
    { title: "Santo Antão", image: "santo-antao.jpg", onshore: { none: 232094,   low: 230.77,  medium: 17.68,   high: 0 },     offshore: { low: 0,      medium: 32.52,   high: 17.44 }},
    { title: "São Vicente", image: "sao-vicente.jpg", onshore: { none: 24856.44, low: 808.62,  medium: 498.18,  high: 0 },     offshore: { low: 0,      medium: 146.46,  high: 0.18 }},
    { title: "São Nicolau", image: "sao-nicolau.jpg", onshore: { none: 67457.58, low: 380.24,  medium: 151.87,  high: 0 },     offshore: { low: 0,      medium: 14.05,   high: 0 }},
  ]
}

export { Config }
