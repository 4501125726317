import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  onClick: () => void;
}

const GuideButtonBase = (props: IProps) => 
  <span className={props.className} onClick={props.onClick}>
    {props.children}
  </span>

const GuideButton = styled(GuideButtonBase)`
  float: right;
  color: #37ACE3;
  border: solid 1px #37ACE3;
  border-radius: 4px;
  padding-left: 6px;
  padding-right: 6px;
  margin-left: 2px;
  margin-right: 2px;
  padding-top: 2px;
  padding-bottom: 2px;
  cursor: pointer;
  user-select: none;
  &:hover {
    background: #333;
  }
`

export { GuideButton }
