import * as React from 'react';
import { createRoot } from 'react-dom/client';

import { Config } from './config/Config';

import Map, { MapboxGeoJSONFeature, MapboxMap, MapLayerMouseEvent, Popup, ViewState, ViewStateChangeEvent } from 'react-map-gl';

import { PianoKey } from './components/PianoKey';
import { PianoKeyList } from './components/PianoKeyList';
import { Table } from './components/Table';
import { PopupBalloon } from './components/PopupBalloon';
import { PopupBox } from './components/PopupBox';
import { PopupTitle } from './components/PopupTitle';
import { LayerButton } from './components/LayerButton';

import { CustomizableLayer } from './layer/CustomizableLayer';
import { ILayer } from './config/Config.Type';
import { TranslatedName, TranslatedValue } from './formatters';
import { COLOR_HIGHLY_SUITABLE, COLOR_MODERATELY_SUITABLE, COLOR_NOT_SUITABLE, COLOR_SUITABLE } from './config/Colors';

// Mapbox controls:
import { ScaleControl } from "@independent-software/mapbox-ext/controls/ScaleControl";
import { ZoomInButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomInButton";
import { ZoomOutButton } from "@independent-software/mapbox-ext/controls/buttons/ZoomOutButton";
import { CompassButton } from "@independent-software/mapbox-ext/controls/buttons/CompassButton";
import { FullscreenButton } from "@independent-software/mapbox-ext/controls/buttons/FullscreenButton";
import { Legend } from "@independent-software/mapbox-ext/controls/Legend";
import { LegendBox } from "@independent-software/mapbox-ext/controls/Legend/LegendBox";
import { DarkSkin } from '@independent-software/mapbox-ext/types/Skin';
import { Guide } from './guide/Guide';

interface IState {
  viewState: ViewState;

  satellite: boolean;
  interactiveLayerIds: string[];

  hoverLatitude: number;
  hoverLongitude: number;
  hoverFeatures: MapboxGeoJSONFeature[];
  popup_horizontal: 'left' | 'right';
  popup_vertical: 'top' | 'bottom';

  layers: { [key:string]: ILayer };
}

class MapView extends React.Component<{}, IState> {
  private map: MapboxMap = null;
  private hovered: MapboxGeoJSONFeature[] = [];

  state: IState = {
    viewState: {
      longitude: Config.longitude, 
      latitude: Config.latitude, 
      zoom: Config.minZoom,
      bearing: 0,
      pitch: 0,
      padding: { top: 0, bottom: 0, right: 0, left: 0 }
    },

    satellite: true,
    interactiveLayerIds: [],

    hoverLatitude: 0,
    hoverLongitude: 0,
    hoverFeatures: [],
    popup_horizontal: 'left',
    popup_vertical: 'top',

    layers: Config.layers
  };

  handleLoad = (e: mapboxgl.MapboxEvent) => {
    this.map = e.target;
    this.setState({
      interactiveLayerIds: Object.entries(Config.layers).map(([key, layer]) => key)
    });
  }  

  handlePianoKey = (name: string) => {
    this.state.layers[name].active = !this.state.layers[name].active;
    this.setState({ layers: this.state.layers });
  }

  handleToggleSatellite = () => {
    this.setState({ satellite: !this.state.satellite });
  }

  //
  // Gets current window size.
  // Returns [width, height].
  // 
  private getWindowSize = (): number[] => {
    const width  = window.innerWidth || document.documentElement.clientWidth || 
                   document.body.clientWidth;
    const height = window.innerHeight|| document.documentElement.clientHeight|| 
                   document.body.clientHeight;
    return [width, height];
  }

  handleMouseMove = (e: MapLayerMouseEvent) => {
    // If source isn't yet loaded, MapGL throws an error:
    if(!this.map) return;

    const [width, height] = this.getWindowSize();
    const dx = e.point.x - width / 2;
    const dy = e.point.y - height / 2;
    this.setState({
      popup_horizontal: dx < 0 ? 'left' : 'right',
      popup_vertical:   dy < 0 ? 'top' : 'bottom'
    });

    this.hovered.forEach(f => {
      this.map.setFeatureState(f, { hover: false });
    });

    this.hovered = e.features;
    this.hovered.forEach(f => {
      this.map.setFeatureState(f, { hover: true });
    });

    this.setState({
      hoverLatitude: e.lngLat.lat,
      hoverLongitude: e.lngLat.lng,
      hoverFeatures: e.features
    });
  } 

  getLayerTable = (feature: MapboxGeoJSONFeature): React.ReactNode => {
    // Get all properties from Feature, and produce table rows:
    const rows: React.ReactNode[] = [];
    Object.keys(feature.properties).forEach((k, idx) => {
      rows.push(<tr key={idx++}>
        <td><TranslatedName name={k}/></td>
        <td><TranslatedValue name={k} value={feature.properties[k]}/></td>
      </tr>);
    });
    // Build and return table:
    return (
      <Table>
        <tbody>
          {rows}
        </tbody>
      </Table>
    );
  }

  getPopupContent = (features: MapboxGeoJSONFeature[]): React.ReactNode => {
    // Which layers are in the features?
    let layerIDs: string[] = [];
    features.forEach(f => {
      features.forEach(f => {
        if(layerIDs.indexOf(f.layer.id) === -1) {
          layerIDs.push(f.layer.id);
        }
      });
    });

    // Build a popup box for each layer:
    const boxes = layerIDs.map((layerID, idx) => 
      <PopupBox key={idx}>
        <PopupTitle>{Config.layers[layerID].name}</PopupTitle>
        {this.getLayerTable(features.find(f => f.layer.id == layerID))}
      </PopupBox>
    );

    // Return popup boxes:
    return <>{boxes}</>;
  }

  handleMove = (e: ViewStateChangeEvent) => {
    this.setState({
      viewState: e.viewState
    });
  }  

  render = () => {
    return (
      <Map
        {...this.state.viewState}
        mapboxAccessToken={Config.mapKey}
        style={{width: '100%', height: '100%'}}
        cursor={this.state.hoverFeatures.length > 0 ? 'pointer' : 'auto'}
        logoPosition="bottom-left"
        interactiveLayerIds={this.state.interactiveLayerIds}
        mapStyle={this.state.satellite ? Config.satelliteStyle : Config.greyStyle}
        scrollZoom={false}
        maxBounds={Config.max_bounds}
        minZoom={Config.minZoom}
        maxZoom={Config.maxZoom}
        onLoad={this.handleLoad}
        onMouseMove={this.handleMouseMove}
        onMove={this.handleMove}
      >
        {/* Map controls */}
        <ScaleControl {...this.state.viewState} x={40} y={-40} width={200}/>
        <FullscreenButton skin={DarkSkin} {...this.state.viewState} x={40} y={-260} hint={<>Toggle full screen</>}/>
        <LayerButton active={this.state.satellite} x={40} y={-215} hint={<>Toggle satellite layer</>} onClick={this.handleToggleSatellite}/>
        <ZoomInButton  skin={DarkSkin} {...this.state.viewState} x={40} y={-170} hint={<>Zoom in</>}/>
        <ZoomOutButton skin={DarkSkin} {...this.state.viewState} x={40} y={-125} hint={<>Zoom out</>}/>
        <CompassButton skin={DarkSkin} {...this.state.viewState} x={40} y={-80} hint={<>Reset bearing to north</>} visualizePitch/>
        
        {/* Piano keys */}
        <PianoKeyList>
          {Object.entries(Config.layers).map(([key, layer]) => 
            <PianoKey key={key} active={this.state.layers[key].active} icon={layer.icon} title={layer.name} onClick={() => this.handlePianoKey(key)}/>
          )}
        </PianoKeyList>

        {/* Layers */}
        {Object.entries(Config.layers).map(([key, layer]) => 
          <CustomizableLayer key={key} id={key} {...layer} active={this.state.layers[key].active}/>
        )}

        {/* Legend */}
        <Legend x={-20} y={-80} skin={DarkSkin}>
          <LegendBox color={COLOR_NOT_SUITABLE} label="Not suitable"/>
          <LegendBox color={COLOR_MODERATELY_SUITABLE} label="Moderately suitable"/>
          <LegendBox color={COLOR_SUITABLE} label="Suitable"/>
          <LegendBox color={COLOR_HIGHLY_SUITABLE} label="Highly suitable"/>
        </Legend>

        <Guide/>

        {this.state.hoverFeatures.length > 0 &&
          <Popup
              longitude={this.state.hoverLongitude}
              latitude={this.state.hoverLatitude}
              offset={[this.state.popup_horizontal == 'left' ? -35 : 35, 
                      this.state.popup_vertical == 'top' ? 40 : -30]}
              closeButton={false}
              anchor={`${this.state.popup_vertical}-${this.state.popup_horizontal}` as any}
              maxWidth="1000px"
              closeOnClick={false}
            >
              <PopupBalloon horizontal={this.state.popup_horizontal} vertical={this.state.popup_vertical}>
                {this.getPopupContent(this.hovered)}
              </PopupBalloon>
          </Popup>}
       
      </Map>
    );
  }
}

const node = document.getElementById('mapview') as Element;
if(node) {
  const root = createRoot(node);
  root.render(<MapView/>);
}

// Whenever webpack rebuilds the project, refresh the browser.
declare let module: any;
if (module.hot) {
  module.hot.accept(); 
}
