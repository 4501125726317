import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideIcon } from '../elements/GuideIcon';
import { GuideButton } from '../elements/GuideButton';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const TiltGuide = (props: IProps) => {
  return (
    <GuideBox bottom="92px" left="92px" onCancel={props.onCancel}>
      <h1>Rotating and tilting</h1>
      <p><em>The map can be both rotated (changing its bearing) and tilted
        (changing the view's axis).</em></p>
      <p><b>Ctrl+click</b> and <b>drag</b> to rotate and tilt the map.</p>
      <p>Click the <GuideIcon icon="compass"/> icon to reset your view to due north.</p>
      <GuideButton onClick={() => props.onStage(null)}>Finish</GuideButton>
    </GuideBox>
  );
}

export { TiltGuide }
