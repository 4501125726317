import styled from 'styled-components';

const Table = styled('table')`
  width: 100%;
  border-collapse: separate;

  td, th {
    padding: 0;
    margin: 0;
    text-align: left;
    height: 24px;
    max-height: 24px;
    white-space: nowrap;
    border: none;
    color: #fff;
    user-select: none;
    color: #eee;
    vertical-align: middle;
  }

  th {
    color: lightblue;
  }

  td:first-child {
    width: 130px;
  }

  td:last-child {
    font-weight: bold;
    color: #fff;
  }
`

export { Table }
