import * as React from 'react';
import axios from 'axios';
import { Source } from 'react-map-gl';

import { AnimatedLoader } from '@independent-software/mapbox-ext/controls/AnimatedLoader';

// Loading delay is BASE + RANDOM.
const LOAD_DELAY_RANDOM = 0;  // Random loading delay (ms)
const LOAD_DELAY_BASE   = 0;  // Base loading delay (ms)

interface IProps {
  /** @ignore */
  children?: React.ReactNode;
  /** GeoJSON file name. */
  path: string;
  /** Is layer currently active? */
  active: boolean;
  /** List of properties to ignore from GeoJSON data. */  
  ignore?: string[]
}

/**
 * Loads a geojson resource (path) from the server. onLoading is fired when
 * loading begins; onLoaded is fired when loading is complete. Renders a 
 * Source which may contain Layer children.
 */
const LoadableLayer = (props: IProps) => {
  // Is the layer currently loading?
  const [loading, setLoading] = React.useState<boolean>(false);
  // Layer's GeoJSON data:
  const [geojson, setGeojson] = React.useState<GeoJSON.FeatureCollection>(null);

  // Loading begins when component is first mounted.
  React.useEffect(() => {
    if(props.active) loadData();
  }, []);

  // Data is reloaded when active status changes.
  React.useEffect(() => {
    if(props.active) loadData();
  }, [props.active])

  const loadData = () => {
    setLoading(true);
    window.setTimeout(() => {
      axios.get(`data/${props.path}`)
      .then(res => {
        const geojson: GeoJSON.FeatureCollection = res.data;
        // Filter GeoJSON for ignored properties:
        if(props.ignore) {
          geojson.features.forEach(f => {
            f.properties = Object.keys(f.properties)
              .filter(key => !props.ignore.includes(key))
              .reduce((obj, key) => {
                (obj as any)[key] = f.properties[key];
                return obj;
              }, {});
          });
        }
        setLoading(false);
        setGeojson(geojson);
      });
    }, Math.random() * LOAD_DELAY_RANDOM + LOAD_DELAY_BASE);
  }

  return (
    <>
      {/* generateId creates an ID for each feature, since the source data
          does not have any IDs. This is required for hovering. */}
      <Source id={props.path} generateId type="geojson" data={geojson}>
        {props.children}
      </Source>
      {loading && <AnimatedLoader x={-40} y={40} active/>}
    </>
  );
}

export { LoadableLayer }
