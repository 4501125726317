import * as React from 'react';
import { Layer } from 'react-map-gl';

import { ILayer } from '../config/Config.Type';
import { LoadableLayer } from './LoadableLayer';

/**
 * A CustomizableLayer is a LoadableLayer that takes a layer specification.
 */
const CustomizableLayer = (props: ILayer & { id: string, active: boolean}) => {
  return (
    <LoadableLayer path={props.src} {...props}>
      {props.fillPaint && <Layer
        id={props.id}
        type="fill"
        layout={{ visibility: props.active ? "visible" : "none" }}
        paint={props.fillPaint}
      />}
      {props.linePaint && <Layer
        type="line"
        layout={{ visibility: props.active ? "visible" : "none" }}
        paint={props.linePaint}
      />}
    </LoadableLayer>
  )
}

export { CustomizableLayer }
