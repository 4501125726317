import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

class PopupBoxBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    );
  }
}

const PopupBox = styled(PopupBoxBase)`
  box-sizing: border-box;
  border: solid 1px #fff;
  border-radius: 4px;
  background: #333;
  padding: 10px;
  box-shadow: 2px 2px 2px rgb(0,0,0,0.5);
`

export { PopupBox }
