import * as React from 'react';
import { WelcomeGuide } from './guides/WelcomeGuide';
import { NavigationGuide } from './guides/NavigationGuide';
import { ZoomGuide } from './guides/ZoomGuide';
import { TiltGuide } from './guides/TiltGuide';
import { SatelliteGuide } from './guides/SatelliteGuide';
import { FullscreenGuide } from './guides/FullscreenGuide';
import { LayersGuide } from './guides/LayersGuide';

const Guide = () => {
  const [active, setActive] = React.useState(true);
  const [stage, setStage] = React.useState(null);

  const handleCancel = () => setActive(false);
  const handleStage = (s: string) => setStage(s);

  // If guide is inactive, render nothing.
  if(active === false) return null;

  // Render guide page based on state.
  if(stage === null) return <WelcomeGuide onCancel={handleCancel} onStage={handleStage}/>
  if(stage === 'navigation') return <NavigationGuide onCancel={handleCancel} onStage={handleStage}/>
  if(stage === 'zooming') return <ZoomGuide onCancel={handleCancel} onStage={handleStage}/>
  if(stage === 'tilting') return <TiltGuide onCancel={handleCancel} onStage={handleStage}/>
  if(stage === 'satellite') return <SatelliteGuide onCancel={handleCancel} onStage={handleStage}/>
  if(stage === 'fullscreen') return <FullscreenGuide onCancel={handleCancel} onStage={handleStage}/>  
  if(stage === 'layers') return <LayersGuide onCancel={handleCancel} onStage={handleStage}/>

  return null;
}

export { Guide }
