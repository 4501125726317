import * as React from 'react';
import styled from 'styled-components';
import { IBrick } from './config/Config.Type';
import { Number } from './formatters';
import { LegendBox } from '@independent-software/mapbox-ext/controls/Legend/LegendBox';
import { COLOR_HIGHLY_SUITABLE, COLOR_MODERATELY_SUITABLE, COLOR_NOT_SUITABLE, COLOR_SUITABLE } from './config/Colors';

interface IProps {
  className?: string;
  data: IBrick;
}

const BrickBase = (props: IProps) => {
  return (
    <div className={props.className}>
      <img src={`assets/img/${props.data.image}`} />
      <Title>
        {props.data.title}
      </Title>
      <Text>
        <table>
          <tbody>
            <tr>
              <th colSpan={2}>Offshore aquaculture</th>            
            </tr>
            <tr><td><LegendBox color={COLOR_HIGHLY_SUITABLE}/> Highly suitable</td><td><Number value={props.data.offshore.high} decimals={2}/> km&sup2;</td></tr>
            <tr><td><LegendBox color={COLOR_SUITABLE}/> Suitable</td><td><Number value={props.data.offshore.medium} decimals={2}/> km&sup2;</td></tr>
            <tr><td><LegendBox color={COLOR_MODERATELY_SUITABLE}/> Moderately suitable</td><td><Number value={props.data.offshore.low} decimals={2}/> km&sup2;</td></tr>
            <tr>
              <th colSpan={2}>Onshore aquaculture</th>            
            </tr>            
            <tr><td><LegendBox color={COLOR_HIGHLY_SUITABLE}/> Highly suitable</td><td><Number value={props.data.onshore.high*0.01} decimals={2}/> km&sup2;</td></tr>            
            <tr><td><LegendBox color={COLOR_SUITABLE}/> Suitable</td><td><Number value={props.data.onshore.medium*0.01} decimals={2}/> km&sup2;</td></tr>
            <tr><td><LegendBox color={COLOR_MODERATELY_SUITABLE}/> Moderately suitable</td><td><Number value={props.data.onshore.low*0.01} decimals={2}/> km&sup2;</td></tr>
            <tr><td><LegendBox color={COLOR_NOT_SUITABLE}/> Not suitable</td><td><Number value={props.data.onshore.none*0.01} decimals={2}/> km&sup2;</td></tr>
          </tbody>
        </table>
      </Text>
    </div>
  )
}

const Title = styled.div`
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content:  center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  background: white;
  border-top: solid 1px #aaa;
  font-weight: bold;
  transition: background-color ease-in-out 100ms;
`

const Text = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  padding: 20px 20px;
  color: #333;
  transition: opacity ease-in-out 100ms;
  table {
    width: 100%;
    td:first-child {
      gap: 8px;
      display: flex;
    }
    td:last-child {
      text-align: right;
    }
  }
`

const Brick = styled(BrickBase)`
  position: relative;
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 8px;
  border: solid 2px #eee;
  transition: border-color ease-in-out 100ms;
  user-select: none;
  img {
    flex-shrink: 0;
    min-width: 100%;
    min-height: 100%;
    object-fit: cover;
    transition: opacity ease-in-out 100ms;
  }
  ${Text} {
    opacity: 0;
  }
  &:hover {
    border-color: #888;
    ${Text} {
      opacity: 1;
    }
    ${Title} {
      background-color: #eee;
    }
    img {
      opacity: 0.35;
    }
  }
`

export { Brick }

