import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  /** @ignore */
  className?: string;
  /** @ignore */
  children?: React.ReactNode;
  /** Fired when GuideLink is clicked. */
  onClick: () => void;
}

const GuideLinkBase = (props: IProps) => 
  <div className={props.className}>
    <Caret>
      <svg>
        <use xlinkHref={"sprites.svg#caret"}></use>
      </svg>     
    </Caret>
    <a onClick={props.onClick}>
      {props.children}
    </a>
  </div>

const Caret = styled.div`
  width: 10px;
  height: 16px;
  svg {
    fill: #39C5FF;
    width: 10px;
    height: 16px;
  }
`

const GuideLink = styled(GuideLinkBase)`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 6px;
  margin-top: 2px;
  margin-bottom: 2px;

  a {
    color: #39C5FF;
    text-decoration: underline;
    cursor: pointer;
  }
  a:hover {
    color: #49d5FF;
    text-decoration: none;
  }
`

export { GuideLink }
