import * as React from 'react';
import styled from 'styled-components';

interface IProps {
  className?: string;
  children?: React.ReactNode;
}

class PopupTitleBase extends React.Component<IProps> {
  render = () => {
    const p = this.props;
    return (
      <div className={p.className}>
        {p.children}
      </div>
    );
  }
}

const PopupTitle = styled(PopupTitleBase)`
  color: lightblue;
  font-weight: bold;
  font-size: 125%;
  padding-left: 1px;
  padding-bottom: 4px;
  text-shadow: 1px 1px 1px rgb(0,0,0,0.5);
`

export { PopupTitle }
