import { FillPaint, LinePaint } from "mapbox-gl"
import { COLOR_HIGHLY_SUITABLE, COLOR_MODERATELY_SUITABLE, COLOR_NOT_SUITABLE, COLOR_SUITABLE } from "../config/Colors";

class PrebuiltExpressions {

  static FillSuitability: FillPaint = { 
    'fill-color': [
      'step',
      ['get', 'suitability'],
         COLOR_NOT_SUITABLE,
      2, COLOR_MODERATELY_SUITABLE,
      3, COLOR_SUITABLE,
      4, COLOR_HIGHLY_SUITABLE
    ],
    'fill-opacity': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      1,
      0.5
      ]
  }

  static LineBlackGoldResponsive: LinePaint = {
    'line-color': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      'gold',
      'black'
      ],
    'line-width': [
      'case',
      ['boolean', ['feature-state', 'hover'], false],
      3,
      1
      ]
  }

}

export { PrebuiltExpressions }
