import * as React from 'react';

import { IMapButtonProps, MapButton } from '@independent-software/mapbox-ext/controls/buttons/MapButton';
import { DarkSkin } from '@independent-software/mapbox-ext/types/Skin';

interface ILayerButtonProps {
  active?: boolean;
  onClick: () => void;
}

const LayerButton = (p: IMapButtonProps & ILayerButtonProps) => {
  return (
    <MapButton skin={DarkSkin} active={p.active} onClick={p.onClick} {...p}>
      <svg width="19" height="17" viewBox="0 0 19 17" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.98627 0.000502285C8.99242 2.91566e-08 8.9992 0 9.00648 0C9.10153 0 9.19205 0.0213445 9.27254 0.0590102L9.26864 0.0573779L17.6408 3.92185C17.8578 4.02368 18.0059 4.24077 18.0059 4.49164C18.0059 4.7425 17.858 4.9596 17.6448 5.05979L17.6409 5.06142L9.26877 8.92589C9.19168 8.96243 9.10115 8.98327 9.00611 8.98327C8.91106 8.98327 8.82053 8.96193 8.73942 8.92426L8.74332 8.9259L0.371202 5.06142C0.154118 4.9596 0.00609779 4.74251 0.00609779 4.49164C0.00609779 4.24077 0.154005 4.02367 0.367186 3.92349L0.371077 3.92185L8.74319 0.0573805C8.81463 0.0236053 8.89737 0.00276424 8.98501 0L8.98627 0.000502285ZM9.00648 1.31959L2.13221 4.49201L9.00648 7.66507L15.8808 4.49201L9.00648 1.32007V1.31959Z"/>
        <path d="M0.613081 7.72463C0.617601 7.72463 0.623251 7.72413 0.628273 7.72413C0.726709 7.72413 0.819496 7.7466 0.902237 7.78716L0.898344 7.78552L9.00786 11.5285L17.1174 7.78552C17.1945 7.74899 17.285 7.72814 17.38 7.72814C17.7271 7.72814 18.0078 8.00939 18.0078 8.35592C18.0078 8.6074 17.8599 8.82388 17.6467 8.92406L17.6428 8.9257L9.27071 12.7902C9.19363 12.8267 9.1031 12.8475 9.00805 12.8475C8.91301 12.8475 8.82248 12.8262 8.74137 12.7885L8.74526 12.7902L0.373142 8.9257C0.151537 8.82563 0.000226974 8.60678 0.000226974 8.35189C0.000226974 8.01051 0.27306 7.73264 0.612825 7.72412H0.613329L0.613081 7.72463Z"/>
        <path d="M0.613081 11.5884C0.617601 11.5884 0.623251 11.5879 0.628273 11.5879C0.726709 11.5879 0.819496 11.6104 0.902237 11.6509L0.898344 11.6493L9.00786 15.3923L17.1174 11.6493C17.1945 11.6128 17.285 11.5919 17.38 11.5919C17.7271 11.5919 18.0078 11.8732 18.0078 12.2197C18.0078 12.4712 17.8599 12.6876 17.6467 12.7878L17.6428 12.7895L9.27071 16.6539C9.19363 16.6905 9.1031 16.7113 9.00805 16.7113C8.91301 16.7113 8.82248 16.69 8.74137 16.6523L8.74526 16.654L0.373142 12.7895C0.151537 12.6894 0.000226974 12.4706 0.000226974 12.2157C0.000226974 11.8743 0.27306 11.5964 0.612825 11.5879H0.613329L0.613081 11.5884Z"/>
      </svg>
    </MapButton>
  );
}

export { LayerButton }
