import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideButton } from '../elements/GuideButton';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const NavigationGuide = (props: IProps) => {
  return (
    <GuideBox bottom="92px" left="92px" onCancel={props.onCancel}>
      <h1>Navigation</h1>
      <p><em>You can pan, zoom, rotate and tilt the map.</em></p>
      <p>Click and drag them map to <b>pan</b> it.</p>
      <GuideButton onClick={() => props.onStage('zooming')}>Continue</GuideButton>
    </GuideBox>
  );
}

export { NavigationGuide }
