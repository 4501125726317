import * as React from 'react';
import { Config } from '../config/Config';

interface IProps {
  name: string;
}

const TranslatedName = (props: IProps) => {
  const attribute = Config.dictionary.find(x => x.key == props.name);

  // Does key exist? If not, return key.
  if(!attribute) return <>{props.name}</>;
  
  return <>{attribute.text}</>;
}

export { TranslatedName }
