import styled from 'styled-components';

const PianoKeyList = styled('div')`
  position: absolute;
  z-index: 9999;
  right: 0;
  top: 5vh;
  display: flex;
  flex-direction: column;
`

export { PianoKeyList }
