import * as React from 'react';

import { GuideBox } from '../elements/GuideBox';
import { GuideButton } from '../elements/GuideButton';
import { GuideIcon } from '../elements/GuideIcon';

interface IProps {
  onCancel: () => void;
  onStage: (s: string) => void;
}

const SatelliteGuide = (props: IProps) => {
  return (
    <GuideBox bottom="92px" left="92px" onCancel={props.onCancel}>
      <h1>Satellite imagery</h1>
      <p><em>The map can display eiter satellite imagery or vectorized landmasses.</em></p>
      <p>Click the <GuideIcon icon="layers"/> icon to toggle satellite imagery.</p>
      <GuideButton onClick={() => props.onStage(null)}>Finish</GuideButton>
    </GuideBox>
  );
}

export { SatelliteGuide }
