import * as React from 'react';
import { createRoot } from 'react-dom/client';
import styled from 'styled-components';
import { Config } from './config/Config';
import { IBrick } from './config/Config.Type';
import { Brick } from './Brick';

interface IProps {
  className?: string;
}

const MasonryBase = (props: IProps) => {
  return (
    <div className={props.className}>
      {Config.masonry.map((b: IBrick) =>
        <Brick data={b} key={b.image}/>
      )}
    </div>
  );
}

const Masonry = styled(MasonryBase)`
  position: relative;
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: repeat(${Math.ceil(Config.masonry.length / 3)}, 18rem);
  gap: 32px;
  @media (max-width: 940px) {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(${Config.masonry.length}, 40vh);
  }
`

const node = document.getElementById('masonry') as Element;
if(node) {
  const root = createRoot(node);
  root.render(<Masonry/>);
}
